import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Image, UnorderedList, Flex } from '@chakra-ui/react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

function Slideshow() {
  const coreValues = ["Commitment", "Entrepreneurship", "Readiness to Act", "Being a Loyal Partner"];
  const photos = ["/images/step_1.png", "/images/step_2.png", "/images/step_3.png"];

  const coreValueSplideRef = useRef(null);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (!coreValueSplideRef.current) return;  // Check for the ref

    const handleSlideChange = () => {
        setAnimationKey(prevKey => prevKey + 1);
    };

    const currentSplide = coreValueSplideRef.current;
    currentSplide.splide.on('moved', handleSlideChange);

    return () => {
      if (currentSplide && currentSplide.splide) {
        currentSplide.splide.off('moved', handleSlideChange);
      }
    };
  }, []);  // Intentionally empty dependency array

  return (
    <Box w="100%" p={{base: '24px', md:'64px'}} display={'flex'} flexDirection={'column'} justify="space-between" h="100%" pos="relative" overflow="hidden" bg='#63849E'>
      {/* Photos Slideshow */}
      <Box pos="absolute" top={0} left={0} w="100%" h="100%">
        <Splide
          style={{'height': '100%'}}
          options={{
            rewind: true,
            drag: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            type: 'loop',
            pagination: false,
            arrows: false,
            autoplay: true,
            pause: false,
            interval: 5000,
            speed: 1000,
          }}
        >
          {photos.map((photo, index) => (
            <SplideSlide key={index}>
              <Image objectFit='cover' w="100%" h="100%" src={photo} alt={`Photo ${index + 1}`} objectPosition="center" />
            </SplideSlide>
          ))}
        </Splide>
      </Box>
      {/* Core Values Slideshow */}
      <Box pos="relative" className="core-values" mt={{base:'8px', md:'0'}}>
        <Splide
          hasTrack={ false }
          ref={coreValueSplideRef}
          options={{
            rewind: true,
            drag: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            type: 'loop',
            arrows: false,
            autoplay: true,
            pause: false,
            interval: 5000,
            speed: 1000,
          }}
        >
          <Flex alignItems={'center'} mb={'24px'}>
            <Text fontSize={'22px'} mt={'3px'} color={'white'} mr={'20px'}>Our Core Values</Text>
            <UnorderedList gap={'6px'} bottom={0} p={0} ml={0} pos={'relative'} className="splide__pagination"></UnorderedList>
          </Flex>
          <SplideTrack>
            {coreValues.map((value, index) => (
              <SplideSlide key={index}>
                <Text className="animate-slide" key={animationKey} color="white" fontSize={{base:'28px', md:'32px'}}>{value}</Text>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>
      </Box>
      {/* <Box pos={'relative'} mt={'auto'} display={{base: 'block', md: 'none'}}>
        <Text color="black" fontSize='14px'>© J4 Investments Ltd 2023</Text>
      </Box> */}
    </Box>
  );
}

export default Slideshow;
