import { extendTheme } from "@chakra-ui/react";

const customColors = {
  white: 'white',
  bg: {
    100: "rgba(0, 55, 99, 0.2)",
    200: '#003763'
  }
};

const theme = extendTheme({
  colors: customColors,
  fonts: {
    body: "Calibri, sans-serif",   
    heading: "Calibri, serif",   
  },
  lineHeights: {
    base: '1.2',
  },
});

export default theme;
