import React, { useEffect } from 'react';
import { Box, Flex } from "@chakra-ui/react";
import Left from "./components/Left";
import Slideshow from "./components/Slideshow";
import './App.css';

function App() {
  useEffect(() => {
    document.title = "J4 Investments";
  }, []);
  return (
    <Box  width="100%" bg="white">
      <Flex h={{md:'100vh'}} minH={'800px'} flexDirection={{base:'column', md:'row'}}>
        <Box w={{md:'60%',xl:'50%'}}>
          <Left />
        </Box>
        <Box w={{md:'40%',xl:'50%'}} h={{base: '567px', md:'auto'}}>
          <Slideshow />
        </Box>
      </Flex>
    </Box>
  );
}

export default App;
