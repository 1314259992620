import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
function Left() {
  return (
    <Flex as="section" h="100%" direction="column">
      <Box h={{ md: '70%', xl: '65%', '2xl': '60%' }} px={{ base: '24px', xl: '64px' }} py="32px">
        <Flex direction="column" h="100%" justify="space-between">
          <Box w={{ base: '130px', md: '250px' }} mb={'50px'}>
            <Image w="250px" h="100%" objectFit={'contain'} src='/images/J4-Investments_Logo.png' alt='J4-Investments Logo' />
          </Box>
          <Box maxW="622px" mb={{ base: '16px', md: '0' }}>
            <Text color="bg.200" fontSize={{ base: '24px', md: '30px' }}>J4 Investments Ltd is an investment firm focused on secured lending and principle investment across a number of asset classes</Text>
          </Box>
        </Flex>
      </Box>
      <Box h={{ md: '30%', xl: '35%', '2xl': '40%' }} bg="bg.100" px={{ base: '24px', xl: '64px' }} py={'30px'}>
        <Flex direction="column" h="100%" justify="space-between">
          <Flex wrap={'wrap'} gap={{ base: '20px', md: '24px' }} justifyContent={{ base: 'center', md: 'start' }}>
            <Link _hover={{ textDecoration: "none" }} alignItems={'center'} pt={{ base: '7px', md: '8px' }} pb={{ base: '9px', md: '8px' }} pr={{ base: '16px', md: '19px' }} pl={{ base: '12px', md: '16px' }} bg={'white'} rounded={'full'} display={'flex'} href="mailto:info@j4investments.com">
              <Image mt={{ base: '0px', md: '0px' }} w={{ base: '20px', md: '26px' }} h={{ base: '20px', md: '26px' }} mr={{ base: '6px', md: '12px' }} src='/images/envelope.svg' alt='Envelope icon' />
              <Text color="bg.200" fontSize={{ base: '20px', md: '20px' }} h='100%'>
                Get in touch
              </Text>
            </Link>
            {/* <Link alignItems={'center'} display={'flex'} href="tel:+442074090777" _hover={{ textDecoration: "none" }}>
              <Image w="24px" h="24px" mr="12px" src='/images/phone.svg' alt='Phone icon' mt={{ base: '0px', md: '2px' }} />
              <Text color="bg.200" fontSize={{ base: '20px', md: '24px' }}>
                +44 207 40 90 777
              </Text>
            </Link> */}
          </Flex>
          <Box display={{ base: 'none', md: 'block' }}>
            {/* <Text textAlign={"right"} color="white" fontSize='18px'>© J4 Investments Ltd 2023</Text> */}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Left;